import { capitalizeWords } from "./capitalizeWords";
import { PROGRAM_STATUSES } from "../config/constants";

export const parseFormProgramData = (client, program) => {
  return {
    ...program,
    clientName: program.clientName,
    clientPhone: program.clientPhone,
    clientEmail: program.clientEmail,
    clientCode: program.clientCode,
    startWeightPounds: program.weight.start.pounds,
    startWeightOunces: program.weight.start.ounces,
    goalWeightPounds: program.weight.goal.pounds,
    goalWeightOunces: program.weight.goal.ounces,
    programName: program.name,
    startDate: program.startDate,
    programLength: program.length,
    bodySculpting: `${program.bodySculpting} sessions`,
    coachName: client.coach.name,
    coachId: client.coach.id,
    programPhase: `Phase ${program.phase}`,
    averageWeightLoss: `${program.averageWeightLoss.pounds} lbs ${program.averageWeightLoss.ounces} oz`,
    daysInRound:
      program.status !== PROGRAM_STATUSES.UPCOMING
        ? `${program.daysInRound.numberOfDays} of ${program.daysInRound.totalDays}`
        : "-",
    programStatus: capitalizeWords(program.triageStatus.color),
    programInteractionType: program.interactionType
  };
};
