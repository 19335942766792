import React from "react";
import { newClientSchemasByStep } from "../../../validations/clients";
import { FieldsByStep } from "./FieldsByStep";
import { useMutation } from "@tanstack/react-query";
import { ClientsController } from "../../../networking/controllers/clients-controller";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useCurrentCoach } from "../../../coaches/hooks/queries/useCurrentCoach";
import { Wizard } from "../../../common/Wizard";

import { SELECT_PLACEHOLDERS } from "../../../utils/constants";
import { constants } from "../../../config/constants";

const NewClientWizard = ({ setShowWizard }) => {
  const coach = useCurrentCoach();
  const queryClient = useQueryClient();

  const initialValues = {
    phase: 1,
    programName: "Reveal Lifestyle Program",
    coachId: coach.coachType === "admin" ? coach.id : SELECT_PLACEHOLDERS.COACH,
    programInteractionType: SELECT_PLACEHOLDERS.PROGRAM_INTERACTION_TYPE
  };

  const mutation = useMutation({
    mutationFn: data => ClientsController.createClient({ data }),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["clients"] });
    },
    onSettled: () => {
      setShowWizard(false);
    }
  });

  const onSubmit = async data => {
    const client = {
      name: data.clientName,
      phone: data.clientPhone,
      email: data.clientEmail,
      coach_id: data.coachId
      // code: data.userPasswordCredential
    };

    const program = {
      start_weight_pounds: data.startWeightPounds,
      start_weight_ounces: data.startWeightOunces,
      goal_weight_pounds: data.goalWeightPounds,
      goal_weight_ounces: data.goalWeightOunces,
      phase: data.phase,
      // name: data.programName,
      length: data.programLength,
      body_sculpting: data.bodySculpting,
      start_date: data.startDate,
      interaction_type: data.programInteractionType
    };

    try {
      await toast.promise(
        mutation.mutateAsync({
          client,
          program
        }),
        {
          loading: "Creating client",
          success: "Client was successfully created",
          error: "Couldn't create client"
        }
      );
    } catch (error) {}
  };

  const onNext = async (formMethods, currentStep, callback) => {
    try {
      const validStep = await formMethods.trigger();
      if (!validStep) return;

      if (currentStep === 1) {
        const clientEmail = formMethods.getValues().clientEmail;
        const splitedName = formMethods
          .getValues()
          .clientName.split(" ")
          .filter(str => str !== "");

        const firstName = splitedName[0];
        const lastName = splitedName[splitedName.length - 1];
        const code = `${firstName[0]}.${lastName}`.toUpperCase();

        formMethods.setValue("userEmailCredential", clientEmail);
        formMethods.setValue("userPasswordCredential", code);
      }

      callback();
    } catch (error) {
      toast.error("Something went wrong in client creation");
    }
  };

  return (
    <Wizard
      title="Create new client"
      initialValues={initialValues}
      schemaByStep={newClientSchemasByStep}
      totalSteps={constants.NEW_CLIENT_WIZARD.TOTAL_STEPS}
      onNext={onNext}
      onSubmit={onSubmit}
      setShowWizard={setShowWizard}
    >
      {currentStep => (
        <div className="w-full pb-10">
          <FieldsByStep step={currentStep} />
        </div>
      )}
    </Wizard>
  );
};

export default NewClientWizard;
