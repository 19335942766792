export class ProgramSerializer {
  static deserialize(program) {
    return {
      id: program.id,
      clientId: program.attributes.client_id,
      clientName: program.attributes.client_name,
      clientEmail: program.attributes.client_email,
      clientCode: program.attributes.client_code,
      clientPhone: program.attributes.client_phone,
      weight: {
        start: {
          pounds: program.attributes.start_weight_pounds,
          ounces: program.attributes.start_weight_ounces
        },
        goal: {
          pounds: program.attributes.goal_weight_pounds,
          ounces: program.attributes.goal_weight_ounces
        }
      },
      name: program.attributes.name,
      length: program.attributes.length,
      bodySculpting: program.attributes.body_sculpting,
      phase: program.attributes.phase,
      startDate: program.attributes.start_date,
      status: program.attributes.status,
      averageWeightLoss: {
        pounds: program.attributes.average_weight_loss.pounds,
        ounces: program.attributes.average_weight_loss.ounces
      },
      daysInRound: {
        numberOfDays: program.attributes.days_in_round.number_of_days,
        totalDays: program.attributes.days_in_round.total_days
      },
      triageStatus: {
        color: program.attributes.triage_status.color,
        isAuto: program.attributes.triage_status.is_auto
      },
      endDate: program.attributes.end_date,
      allowUpdate: program.attributes.allow_update,
      interactionType: program.attributes.interaction_type
    };
  }
}
