import React from "react";
import { Field } from "../../common/Field";
import { PhoneFiled } from "../../common/PhoneField";
import { Select } from "../../common/Select";
import { PROGRAM_INTERACTION_TYPES } from "../../config/constants";

export default function ClientFields({ program, editable, setEditable }) {
  return (
    <div className="w-full relative p-10 bg-white rounded-lg shadow-container">
      {program.status !== "finished" && (
        <button
          type={!editable ? "submit" : "button"}
          className={`absolute top-4 right-1 px-5 py-1 text-neutral-900 text-xs font-monts font-semibold cursor-pointer text-c ${
            editable && "text-complementary-success"
          }`}
          onClick={() => {
            setEditable(prev => !prev);
          }}
        >
          {editable ? "Save" : "Edit"}
        </button>
      )}
      <h3
        className={`font-monts font-bold text-xs p-0 mb-6 text-neutral-100 ${
          editable && "text-neutral-900"
        }`}
      >
        Personal Information
      </h3>
      <Field label="Full Name" name="clientName" disabled={!editable} />
      <PhoneFiled label="Phone" name="clientPhone" disabled={!editable} />
      <Field label="Email" name="clientEmail" disabled={!editable} />
      <Field label="Password" name="clientCode" disabled />
      <Select
        containerStyles="mb-6"
        name="programInteractionType"
        label="Interaction"
        disabled={!editable}
      >
        {PROGRAM_INTERACTION_TYPES.map(({ label, value }, index) => (
          <option value={value} key={`${value}-${index}`}>
            {label}
          </option>
        ))}
      </Select>
      <div>
        <h3
          className={`font-monts font-bold text-xs p-0 mb-6 text-neutral-100 ${
            editable && "text-neutral-900"
          }`}
        >
          Start Weight
        </h3>
        <div className="w-full flex gap-4">
          <Field
            className="text-center mt-2"
            label="Pounds"
            name="startWeightPounds"
            disabled={!editable}
          />
          <Field
            className="text-center mt-2"
            label="Ounces"
            name="startWeightOunces"
            disabled={!editable}
          />
        </div>
      </div>
      <h3
        className={`font-monts font-bold text-xs p-0 mb-6 text-neutral-100 ${
          editable && "text-neutral-900"
        }`}
      >
        Goal Weight
      </h3>
      <div className="w-full flex gap-4">
        <Field
          className="text-center mt-2"
          label="Pounds"
          name="goalWeightPounds"
          disabled={!editable}
        />
        <Field
          className="text-center mt-2"
          label="Ounces"
          name="goalWeightOunces"
          disabled={!editable}
        />
      </div>
    </div>
  );
}
