import React, { useEffect, useState } from "react";

import { useClient } from "../clients/hooks/queries/useClient";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useCurrentCoach } from "../coaches/hooks/queries/useCurrentCoach";

import { editClientForm } from "../validations/clients";
import { ProgramsController } from "../networking/controllers/programs-controller";
import { ClientsController } from "../networking/controllers/clients-controller";

import { App } from "../App";
import { Form } from "../common/Form";
import { toast } from "react-hot-toast";
import { parseFormProgramData } from "../utils/parseFormProgramData";
import { NewProgramModal } from "../clients/components/NewProgram";
import { ShowClientProvider } from "../clients/contexts/ShowClientProvider";

import NavBar from "../common/NavBar";
import ClientFields from "../clients/components/ClientFields";
import ProgramFields from "../clients/components/ProgramFields";
import ProgramSwitch from "../clients/components/ProgramSwitch";
import ExpandedDailyInputsRecords from "../daily-inputs/components/ExpandedDailyInputsRecords";
import SwitchClientStatus from "../clients/components/SwitchClientStatus";
import { NoteWithSwitch } from "../common/NoteWithSwitch";

const ShowClient = () => {
  const clientId = window.location.pathname.split("/").pop();

  const queryClient = useQueryClient();
  const client = useClient(clientId);
  const coach = useCurrentCoach();
  const initialValues = parseFormProgramData(client, client.lastProgram);

  const [editable, setEditable] = useState(false);
  const [programState, setProgramState] = useState(null);

  const program = programState ?? initialValues;

  const updateClientMutation = useMutation(({ where, data }) =>
    ClientsController.updateClient({ where, data })
  );

  const updateProgramMutation = useMutation(({ where, data }) =>
    ProgramsController.updateProgram({ where, data })
  );

  const onSubmit = async (data, formMethods) => {
    const clientData = {
      where: {
        id: client.id
      },
      data: {
        name: data.clientName,
        phone: data.clientPhone,
        email: data.clientEmail,
        coach_id: data.coachId
      }
    };

    const programData = {
      where: {
        id: "last",
        clientId: client.id
      },
      data: {
        start_weight_pounds: data.startWeightPounds,
        start_weight_ounces: data.startWeightOunces,
        goal_weight_pounds: data.goalWeightPounds,
        goal_weight_ounces: data.goalWeightOunces,
        length: data.programLength,
        start_date: data.startDate,
        interaction_type: data.programInteractionType
      }
    };

    try {
      await toast.promise(
        Promise.all([
          updateClientMutation.mutateAsync(clientData),
          updateProgramMutation.mutateAsync(programData)
        ]),
        {
          loading: "Updating client",
          success: "Client was successfully updated",
          error: "Couldn't update client"
        }
      );
    } catch (error) {
    } finally {
      await queryClient.refetchQueries();
      const { lastProgram, ...clientData } = queryClient.getQueryData([
        "client",
        client.id
      ]);

      setProgramState(parseFormProgramData(clientData, lastProgram));
    }
  };

  useEffect(() => {
    if (editable) setEditable(false);
  }, [client, programState]);

  return (
    <>
      <NavBar
        subtitle={
          <p className="font-semibold text-xl">
            {coach.franchise.name} Clients
            <span className="font-normal text-base"> / {client.name}</span>
          </p>
        }
        rightRender={() => {
          return (
            <>
              <SwitchClientStatus client={client} />
              <NewProgramModal client={client} />
            </>
          );
        }}
      >
        <ProgramSwitch client={client} setProgram={setProgramState} />
      </NavBar>
      <div className="w-full bg-neutral-20 p-10 relative">
        <Form
          schema={editClientForm}
          initialValues={program}
          onSubmit={onSubmit}
          onError={(errors, formMethods) => {
            setEditable(true);
          }}
        >
          <div className="w-full flex relative">
            <div className="w-1/3 mr-3">
              <ClientFields
                program={program}
                editable={editable}
                setEditable={setEditable}
              />
            </div>
            <div className="w-2/3 ml-3 flex flex-col gap-4">
              <ProgramFields client={client} editable={editable} />
            </div>
          </div>
        </Form>
        <div className="w-2/3 absolute top-64 bottom-0 right-0 p-10 pl-7">
          <div className="w-full p-4 flex flex-col bg-white rounded-lg shadow-container min-h-50">
            <NoteWithSwitch
              resize
              initialValue={client.note ?? ""}
              maxCharacters={1000}
              placeholder="Enter your note here"
              onSubmit={async note => {
                await toast.promise(
                  updateClientMutation.mutateAsync({
                    where: { id: client.id },
                    data: { note }
                  }),
                  {
                    loading: "Updating notes",
                    success: "Notes was successfully updated",
                    error: "Couldn't update notes"
                  }
                );
              }}
            />
          </div>
          <ExpandedDailyInputsRecords client={client} program={program} />
        </div>
      </div>
    </>
  );
};

export default function () {
  return (
    <App>
      <ShowClientProvider>
        <ShowClient />
      </ShowClientProvider>
    </App>
  );
}
